import { Avatar, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { euro } from "../utils/Currency";

export default function OrderTypes({ orderType, handleOrderType, scheduleList }) {
    return (
        <ToggleButtonGroup
            value={orderType}
            exclusive
            onChange={handleOrderType}
            style={{
                marginTop: 10,
                width: '100%'
            }}
            color="secondary"
        >
            {scheduleList.length > 0 && scheduleList.map((type) => (
                <ToggleButton key={type.id} value={type.id} style={{ flex: 1 }}>
                    <Avatar
                        variant="rounded"
                        alt={type.name}
                        src={`data:image/png;base64,${type.image}`}
                        sx={{ width: 28, height: 28, marginInline: 1, bgcolor: 'inherit', color: 'inherit' }}
                    />
                    {type.name}
                    {type.charge > 0 && ` (+${euro.format(type.charge)})`}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}