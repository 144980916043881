import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Select, TextField } from "@mui/material";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { TicketContext } from '../context/Context';
import { doCheckout, findUserData } from '../service/store.service';
import OrderTypes from './OrderTypes';
import StreetInput from './StreetInput';
import { TicketOrigin } from '../utils/TicketOrigin';

const TpvCheckout = forwardRef((props, ref) => {
    const { deliveryType, scheduleList } = props;
    const ticketContext = useContext(TicketContext);

    const [open, setOpen] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [orderType, setOrderType] = useState();
    const [searching, setSearching] = useState(false);
    const [newUser, setNewUser] = useState(undefined);
    const [showUser, setShowUser] = useState(false);

    const [street, setStreet] = useState(null);
    const [streetDetail, setStreetDetail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState(ticketContext.ticket?.phone ?? '');
    const [mail, setMail] = useState('');
    const [paid, setPaid] = useState(false);
    const [schedule, setSchedule] = useState('');

    const delivery = scheduleList.find((s) => s.id === orderType && !s.customAddress);

    const [error, setError] = useState(false);

    useImperativeHandle(ref, () => ({
        open(pDelivery) {
            setError(false);
            //setDelivery(pDelivery);
            setOrderType(deliveryType);
            setOpen(true);
        }
    }));

    const close = () => {
        setOpen(false);
    }

    const mountTicket = () => {
        ticketContext.ticket = {
            ...ticketContext.ticket,
            //user: user?.id,
            address: `${street} ${streetDetail}`.trim(),
            name: name,
            phone: phone,
            mail: mail,
            scheduled: schedule,
            deliveryType: orderType,
            origin: TicketOrigin.TPV,
            paid: paid
        }

        return ticketContext.ticket;
    }

    const validateMail = () => {
        const validMail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        if (!validMail.test(mail)) {
            return false;
        }
        return true;
    }

    const validate = () => {
        // Comprobar si estan informados todos los campos
        if (!name || !phone || (delivery && (!street || !streetDetail))) {
            setError("Tiene que rellenar los campos necesarios");
            return false;
        }
        // Validar correo
        /*if (!validateMail()) {
            setError("La dirección de correo no es válida");
            return false;
        }*/
        // Comprueba el mínimo para el delivery seleccionado
        const selectedDelivery = scheduleList.find((s) => s.id === orderType);
        if (ticketContext.getTotal(false) < selectedDelivery.minDelivery) {
            setError(`El importe mínimo para ${selectedDelivery.name} es ${selectedDelivery.minDelivery}`);
            return false;
        }
        return true;
    }

    const checkout = () => {
        if (!validate()) return;
        setWaiting(true);
        setError(false);
        // Comprobaciones en el back y redirección al pago o mostrar error
        doCheckout(mountTicket())
            .then((data) => {
                // Mostrar mensaje de Ok y resetear ticket
                // TODO: Cambiar por SnackBar?
                alert(`Ticket ${data.ticketId}`);
                ticketContext.resetTicket();
                window.location.reload();
            })
            .catch((e) => {
                setError(e.response.data);
                console.log("error", e);
            })
            .finally(() => {
                setWaiting(false);
            });
    }

    const handleOrderType = (event, newType) => {
        if (newType !== null) {
            setOrderType(newType);
            setSchedule('');
        }
    };

    const findUser = () => {
        if (!phone) return;
        setSearching(true);
        findUserData(phone)
            .then((user) => {
                setNewUser(!user);

                setName(user?.name ?? '');
                setStreet(user?.street ?? '');
                setStreetDetail(user?.streetDetail ?? '');
                setMail(user?.mail ?? '');
            })
            .catch(e => console.log(e))
            .finally(() => {
                setSearching(false);
                setShowUser(true);
            });
    }

    return (
        <Dialog
            onClose={close}
            open={open}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>
                Finalizar pedido
                <IconButton
                    onClick={close}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} marginTop={0}>
                    <Grid item xs={12}>
                        <TextField
                            name='phone'
                            label="Teléfono"
                            type="number"
                            size="medium"
                            variant="outlined"
                            fullWidth
                            required
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.currentTarget.value);
                                setShowUser(false);
                            }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    findUser();
                                }
                            }}
                            error={!!error && !phone}
                            InputProps={{
                                endAdornment:
                                    <>
                                        {newUser && (<Chip label="Nuevo usuario" color='warning' />)}
                                        <IconButton type="button" onClick={() => findUser()}>
                                            {searching ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <SearchIcon />
                                            )}
                                        </IconButton>
                                    </>
                            }}
                        />
                    </Grid>
                    {showUser && (
                        <>
                            {delivery && (
                                <>
                                    <Grid item xs={12}>
                                        <StreetInput
                                            value={street}
                                            setValue={setStreet}
                                            error={!!error && !streetDetail}
                                        //InputLabelProps={{ shrink: !!street }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name='streetDetail'
                                            label='Número, piso, puerta'
                                            type='text'
                                            size="medium"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            value={streetDetail}
                                            onChange={(e) => setStreetDetail(e.currentTarget.value)}
                                            error={!!error && !streetDetail}
                                            InputLabelProps={{ shrink: !!streetDetail }}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    name='name'
                                    label='Nombre'
                                    type='text'
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.currentTarget.value)}
                                    error={!!error && !name}
                                    InputLabelProps={{ shrink: !!name }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name='mail'
                                    label='Correo electrónico'
                                    type='email'
                                    size="medium"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={mail}
                                    onChange={(e) => setMail(e.currentTarget.value)}
                                    error={!!error && (!mail || !validateMail())}
                                    InputLabelProps={{ shrink: !!mail }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                {showUser && (
                    <Grid container>
                        <Grid item xs={9} style={{
                            alignSelf: 'end',
                            textAlign: 'center'
                        }}>
                            <OrderTypes orderType={orderType} handleOrderType={handleOrderType} scheduleList={scheduleList} />
                        </Grid>
                        <Grid item xs={3} style={{
                            alignSelf: 'center',
                            textAlign: 'center'
                        }}>
                            <Checkbox value={paid} onChange={() => setPaid(!paid)} /> Pagado
                            <Button
                                variant="contained"
                                //sx={{ width: { xs: '100%', md: '40%' } }}
                                disabled={waiting}
                                onClick={checkout}
                            >
                                {waiting ? "Comprobando..." : "Finalizar"}
                                {waiting && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            //color: green[500],
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Button>
                        </Grid>
                        <Grid item xs={12} style={{ paddingBlock: 10 }}>
                            Hora de entrega:
                            <Select
                                value={schedule}
                                onChange={(e) => setSchedule(e.target.value)}
                                fullWidth
                                size="small"
                                displayEmpty
                            >
                                <MenuItem value='' selected>Lo antes posible</MenuItem>
                                {scheduleList.find((s) => s.id === orderType)?.scheduler.map((scheduling, index) => (
                                    <MenuItem key={index} value={scheduling}>{scheduling}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        {error && (
                            <Grid item xs={12}>
                                <Alert severity='error' style={{ width: '100%' }}>{error}</Alert>
                            </Grid>
                        )}
                    </Grid>
                )}
            </DialogActions>
        </Dialog>
    );
});

export default TpvCheckout;