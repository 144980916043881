import styled from "@emotion/styled";
import CancelIcon from '@mui/icons-material/CancelRounded';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DiscountIcon from '@mui/icons-material/Discount';
import EditIcon from '@mui/icons-material/Edit';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { Fragment, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { TicketContext } from "../context/Context";
import { checkVoucher, getOpened, getScheduleList } from "../service/store.service";
import { euro } from "../utils/Currency";
import Checkout from "./Ckeckout";
import ItemDetail from "./ItemDetail";
import OrderTypes from "./OrderTypes";
import TpvCheckout from "./TpvCkeckout";
import FreeItemDetail from "./FreeItemDetail";

const CommentCell = styled(TableCell)(({ theme }) => ({
    //backgroundColor: theme.palette.info.light,
    color: '#a0a0a0', //theme.palette.info.contrastText,
    fontSize: 12,
    paddingBottom: 1,
    paddingTop: 1
}));

const ExtraCell = styled(TableCell)(({ theme }) => ({
    fontSize: 11,
    paddingLeft: 25,
    paddingTop: 2,
    paddingBottom: 2,
    borderBottom: 'unset',
    //color: theme.palette.text.secondary,
}));

const ItemCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'unset',
    //color: theme.palette.text.secondary,
}));

const DiscountButton = () => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const { addVoucher } = useContext(TicketContext);

    const handleOpen = (open) => {
        setError(null);
        setValue('');
        setLoading(false);
        setOpen(open);
    }

    const validateVoucher = () => {
        setLoading(true);
        checkVoucher(value)
            .then((voucher) => {
                addVoucher(voucher);
            })
            .catch((e) => {
                console.log(e.response.data);
                setError(e.response?.data ?? "error");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        // TODO: Si no esta autenticado, puede aplicar bono???
        <>
            {!open ? (
                <Button size="small" onClick={() => handleOpen(true)} fullWidth startIcon={<DiscountIcon />} style={{ textTransform: 'none' }}>
                    Aplicar código de descuento
                </Button>
            ) : (
                <TextField
                    autoFocus
                    fullWidth
                    type="text"
                    size="small"
                    variant="outlined"
                    label="Código de descuento"
                    onBlur={() => {
                        if (!value) handleOpen(false);
                    }}
                    error={!!error}
                    helperText={error}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <>
                                <IconButton onClick={() => handleOpen(false)}>
                                    <CancelIcon />
                                </IconButton>
                                <Button
                                    variant="contained"
                                    onClick={validateVoucher}
                                    size="small"
                                    style={{ textTransform: 'none', marginRight: '-10px' }}
                                    disabled={loading}
                                >
                                    Aplicar
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Button>
                            </>
                        )
                    }}
                />
            )}
        </>
    );
}

const ConfirmDialog = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState();
    const [handlerFunction, setHandlerFunction] = useState(() => { });

    useImperativeHandle(ref, () => ({
        open(message, func) {
            setOpen(true);
            setMessage(message);
            setHandlerFunction(() => func);
        }
    }));

    const handlerOk = () => {
        handlerFunction();
        handleClose();
    }

    const handleClose = () => {
        setOpen(false);
        setMessage(null);
    }

    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogContent>
                {message}
            </DialogContent>
            <DialogActions>
                <Button onClick={handlerOk}>Sí</Button>
                <Button onClick={handleClose}>No</Button>
            </DialogActions>
        </Dialog>
    )
});

export default function Ticket({ close, tpv }) {
    const ticketContext = useContext(TicketContext);
    const [schedule, setSchedule] = useState('');
    const [scheduleList, setScheduleList] = useState([]);
    const [orderType, setOrderType] = useState();
    const [opened, setOpened] = useState(false);
    const [errors, setErrors] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        Promise.allSettled([
            loadScheduler(),
            getOpened()
                .then(() => setOpened(true))
                .catch(() => setOpened(false))
        ])
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadScheduler = () => {
        getScheduleList(tpv)
            .then((list) => {
                setScheduleList(list);
                const orderType = list.find(t => t.id === ticketContext.ticket?.deliveryType) ?? list[0];
                setOrderType(orderType?.id);
                ticketContext.setDeliveryCharge(orderType?.charge);
                setSchedule(orderType?.opened ? '' : orderType?.scheduler[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleOrderType = (event, newType) => {
        if (newType !== null) {
            setOrderType(newType);
            const selected = scheduleList.find(s => s.id === newType);
            ticketContext.setDeliveryCharge(selected?.charge);
            setSchedule(selected?.opened ? '' : selected?.scheduler[0]);
        }
    };

    const confirmDialog = useRef();

    const resetTicket = () => {
        const text = 'Se van a borrar todos los productos que hay en el ticket. ¿Está seguro?';
        confirmDialog.current.open(text, () => ticketContext.resetTicket());
    }

    const removeItem = (name, index) => {
        const text = `¿Quiere borrar ${name} del pedido?`;
        confirmDialog.current.open(text, () => ticketContext.removeItem(index));
    }

    const details = useRef([]);
    const openDetails = (item, index) => {
        details.current[index].openDetails(item);
    }

    const checkout = useRef();
    const preCheckout = () => {
        const delivery = scheduleList.find((s) => s.id === orderType);
        // Comprueba si hay artículos en el carro
        if (ticketContext.getTotal(false) === 0) {
            setErrors('Tiene que añadir algún artículo para tramitar el pedido');
            return;
        }
        // Comprueba que los artículos incluyen delivery
        /*if (delivery.minDelivery > 0 && !ticketContext.validateDelivery()) {
            setErrors('El pedido no contiene artículos que se puedan enviar con el tipo de entrega seleccionada');
            return;
        }*/
        // Comprueba el mínimo para el delivery seleccionado
        if (ticketContext.getTotal(false) < delivery.minDelivery) {
            setErrors(`El importe mínimo para ${delivery.name} es ${delivery.minDelivery}`);
            return;
        }

        // Mostrar checkout
        checkout.current.open(delivery.minDelivery > 0);
    }

    return (
        <Container sx={{ width: { md: 500, xs: '100%' } }}>
            <IconButton
                onClick={() => close()}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="h5" p={2}>Información del pedido</Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    {/* Para definir los tamaños creamos una cabecera */}
                    <TableHead>
                        <TableRow /*style={{backgroundColor: '#0277bd'}}*/>
                            <TableCell width={20}></TableCell>
                            <TableCell width='auto'></TableCell>
                            <TableCell width='auto'></TableCell>
                            <TableCell width='25%'></TableCell>
                            <TableCell width={30}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ticketContext.ticket?.ticketLines.length === 0 && (
                            <TableRow>
                                <ItemCell colSpan={5} align="center">Todavía no has añadido nada</ItemCell>
                            </TableRow>
                        )}
                        {ticketContext.ticket?.ticketLines.length >= 0 &&
                            ticketContext.ticket?.ticketLines.map((item, index) => (
                                <Fragment key={index}>
                                    <TableRow>
                                        <ItemCell rowSpan={item.extras.length + 1} align="right" style={{ verticalAlign: 'baseline' }}>{item.quantity}</ItemCell>
                                        <ItemCell colSpan={2}>
                                            {item.name}
                                        </ItemCell>
                                        <ItemCell align="right">{euro.format(item.price * item.quantity)}</ItemCell>
                                        <ItemCell rowSpan={item.extras.length + 1} style={{ verticalAlign: 'baseline', paddingRight: 0, paddingLeft: 0 }}>
                                            <IconButton onClick={() => openDetails(item, index)} size="small">
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton onClick={() => removeItem(item.name, index)} size="small" color="error">
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </ItemCell>
                                    </TableRow>
                                    {item.extras &&
                                        item.extras.map((extra, sIndex) => (
                                            <TableRow key={index + '_' + sIndex}>
                                                <ExtraCell colSpan={2}>
                                                    {extra.name}
                                                </ExtraCell>
                                                <ExtraCell align="right">{euro.format(extra.price * item.quantity)}</ExtraCell>
                                            </TableRow>
                                        ))
                                    }
                                    <TableRow>
                                        <CommentCell colSpan={5}>{item.comment}</CommentCell>
                                    </TableRow>
                                    {item.id ? (
                                        <ItemDetail ref={el => details.current[index] = el} itemId={item.id} edit={index} />
                                    ) : (
                                        <FreeItemDetail ref={el => details.current[index] = el} edit={index} />
                                    )}
                                </Fragment>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell rowSpan={4} />
                            <TableCell rowSpan={4} />
                            <TableCell>Subtotal</TableCell>
                            <TableCell align="right">{euro.format(ticketContext.getSubtotal())}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Envío</TableCell>
                            <TableCell align="right">{euro.format(ticketContext.ticket.deliveryCharge/*scheduleList.find((s) => s.id === orderType)?.charge*/)}</TableCell>
                        </TableRow>
                        <TableRow>
                            {ticketContext.ticket?.voucher?.id ? (
                                <>
                                    <TableCell>
                                        <Typography component="span" variant="inherit" style={{ verticalAlign: 'middle' }}>
                                            Descuento {ticketContext.ticket.voucher.id}
                                        </Typography>
                                        <IconButton size="small" color="error" style={{ verticalAlign: 'middle' }} onClick={() => ticketContext.removeVoucher()}>
                                            <CancelIcon fontSize="inherit" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="right" >
                                        <Typography component="span" variant="inherit" style={{ verticalAlign: 'middle' }}>
                                            -{euro.format(ticketContext.ticket.voucher.quantity)}
                                        </Typography>
                                    </TableCell>
                                </>
                            ) : (
                                <TableCell colSpan={2}>
                                    <DiscountButton />
                                </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell align="right">{euro.format(ticketContext.getTotal())}</TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>

            {!tpv && (<Alert severity="info" style={{ marginTop: 10 }}>¿Tienes alguna alergia alimentaria? Llama al 971 66 11 17</Alert>)}

            <OrderTypes orderType={orderType} handleOrderType={handleOrderType} scheduleList={scheduleList} />

            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            )}
            {!tpv && opened && (
                <Alert
                    severity={scheduleList.find((s) => s.id === orderType)?.opened ? 'success' : 'warning'}
                    style={{ marginTop: 10 }}
                    icon={
                        <ScheduleIcon />
                    }
                >
                    {scheduleList.find((s) => s.id === orderType)?.opened ? "Hora de entrega" : "El tipo de entrega no está disponible todavía, pero puedes pedir y programar la entrega"}
                    <Select
                        value={schedule}
                        onChange={(e) => setSchedule(e.target.value)}
                        fullWidth
                        size="small"
                        displayEmpty
                    >
                        {scheduleList.find((s) => s.id === orderType)?.opened && (<MenuItem value=''>Lo antes posible</MenuItem>)}
                        {scheduleList.find((s) => s.id === orderType)?.scheduler.map((scheduling, index) => (
                            <MenuItem key={index} value={scheduling}>{scheduling}</MenuItem>
                        ))}
                    </Select>
                    <Typography variant="caption">
                        * El tiempo de entrega es aproximado
                    </Typography>
                </Alert>
            )}
            <Stack marginBlock={1} spacing={1}>
                {!loading && !opened && !tpv && (<Alert severity="error">Lo sentimos pero no está abierto</Alert>)}
                <Button onClick={preCheckout} disabled={!opened && !tpv} color="success" variant="contained">Tramitar pedido</Button>
                <Button onClick={resetTicket} color="error" variant="outlined">Reiniciar pedido</Button>
            </Stack>

            {/** Ventana errores */}
            <Dialog
                onClose={() => setErrors(null)}
                open={!!errors}
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle>¡Atención!</DialogTitle>
                <DialogContent>
                    {errors}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrors(null)}>Ok</Button>
                </DialogActions>
            </Dialog>

            {/* Ventana domicilio */}
            {tpv ? (
                <TpvCheckout ref={checkout} scheduleList={scheduleList} schedule={schedule} deliveryType={orderType}></TpvCheckout>
            ) : (
                <Checkout ref={checkout} schedule={schedule} deliveryType={orderType}></Checkout>
            )}

            {/* Diálogo */}
            <ConfirmDialog ref={confirmDialog} />
        </Container>
    );
}